var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('vx-input',{attrs:{"rules":_vm.rules.title,"name":"title","label":"Title"},model:{value:(_vm.formValue.title),callback:function ($$v) {_vm.$set(_vm.formValue, "title", $$v)},expression:"formValue.title"}}),_c('vx-checkbox',{attrs:{"rules":_vm.rules.is_enabled,"name":"is_enabled","label":"Enabled"},model:{value:(_vm.formValue.is_enabled),callback:function ($$v) {_vm.$set(_vm.formValue, "is_enabled", $$v)},expression:"formValue.is_enabled"}}),_c('vx-select',{attrs:{"rules":_vm.rules.bonus_type,"options":_vm.bonusTypesOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options-label":"label","name":"bonus_type","label":"Bonus Type"},model:{value:(_vm.formValue.bonus_type),callback:function ($$v) {_vm.$set(_vm.formValue, "bonus_type", $$v)},expression:"formValue.bonus_type"}}),(_vm.bonusResource)?_c('vx-select-resource',{attrs:{"rules":_vm.rules.bonus_id,"resource":_vm.bonusResource,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"suppress-options-pushing":"","options-label":"name","name":"bonus_id","label":"Bonus"},model:{value:(_vm.formValue.bonus_id),callback:function ($$v) {_vm.$set(_vm.formValue, "bonus_id", $$v)},expression:"formValue.bonus_id"}}):_vm._e(),_c('vx-input',{attrs:{"rules":_vm.rules.min_deposit,"name":"min_deposit_sum","label":"Min deposit sum (in $)"},model:{value:(_vm.formValue.min_deposit),callback:function ($$v) {_vm.$set(_vm.formValue, "min_deposit", $$v)},expression:"formValue.min_deposit"}}),_c('vx-input',{attrs:{"rules":_vm.rules.min_wager_sum,"name":"min_wager_sum","label":"Min opens case sum (in $))"},model:{value:(_vm.formValue.min_wager_sum),callback:function ($$v) {_vm.$set(_vm.formValue, "min_wager_sum", $$v)},expression:"formValue.min_wager_sum"}}),_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }