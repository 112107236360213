<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.title"
      :rules="rules.title"
      name="title"
      label="Title"
    />

    <vx-checkbox
      v-model="formValue.is_enabled"
      :rules="rules.is_enabled"
      name="is_enabled"
      label="Enabled"
    />

    <vx-select
      v-model="formValue.bonus_type"
      :rules="rules.bonus_type"
      :options="bonusTypesOptions"
      :reduce="({ value }) => value"
      options-label="label"
      name="bonus_type"
      label="Bonus Type"
    />

    <vx-select-resource
      v-if="bonusResource"
      v-model="formValue.bonus_id"
      :rules="rules.bonus_id"
      :resource="bonusResource"
      :reduce="({ id }) => id"
      suppress-options-pushing
      options-label="name"
      name="bonus_id"
      label="Bonus"
    />

    <vx-input
      v-model="formValue.min_deposit"
      :rules="rules.min_deposit"
      name="min_deposit_sum"
      label="Min deposit sum (in $)"
    />

    <vx-input
      v-model="formValue.min_wager_sum"
      :rules="rules.min_wager_sum"
      name="min_wager_sum"
      label="Min opens case sum (in $))"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { reactive, watch, computed } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxSelect, VxCheckbox } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { bonuses, bonusTypesOptions, welcomeOffers, passDataToResource, useResource } from '@/services/resources'
import { formatToCents, formatToDecimal } from '@/services/utils'
import { apiDefaultValidators } from '@/services/form/validationService'
import VxSelectResource from '@/components/form/VxSelectResource'

export default {
  name: 'WelcomeOfferEditor',
  components: {
    VxSelectResource,
    VxSelect,
    VxForm,
    VxInput,
    VxCheckbox,
    VxButton,
    BModal
  },
  props: {
    welcomeOffer: {
      type: Object,
      default: null
    },
    bonusTypes: {
      type: Array,
      default: () => []
    },
    bonusesOptions: {
      type: Object,
      default: () => {}
    }
  },
  setup ({ welcomeOffer, bonusTypes, bonusesOptions }, { emit }) {
    const isEdit = !!welcomeOffer
    const bonusResource = computed(() => {
      if (!formValue.bonus_type) return null

      const [bonusTypeId] = formValue.bonus_type.split('-')
      return bonuses[bonusTypeId]
    })

    const toggleStatus = async () => {
      const { can, callRequest } = useResource(welcomeOffers.toggleStatus)

      if (can) {
        const { is_enabled } = formValue
        await callRequest({
          ...{ urlParams: { id: welcomeOffer.id } },
          data: { is_enabled }
        })
        emit('updateStatusCallback', true)
      }
    }

    const backToFrontMapper = ({ min_deposit, min_wager_sum }) => {
      return {
        ...welcomeOffer,
        min_deposit: formatToDecimal(min_deposit.amount),
        min_wager_sum: formatToDecimal(min_wager_sum.amount)
      }
    }

    const frontToBackMapper = () => {
      return {
        ...formValue,
        min_deposit: formatToCents(formValue.min_deposit),
        min_wager_sum: formatToCents(formValue.min_wager_sum)
      }
    }

    const formResource = isEdit
      ? passDataToResource(welcomeOffers.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: welcomeOffer.id } }
      })
      : passDataToResource(welcomeOffers.create, {
        frontToBackMapper
      })

    const formValue = reactive({
      title: '',
      is_enabled: false,
      bonus_type: '',
      bonus_id: null,
      min_deposit: null,
      min_wager_sum: null
    })

    watch(() => formValue.is_enabled, (newVal) => {
      if (welcomeOffer?.is_enabled !== newVal && isEdit) {
        toggleStatus()
      }
    })

    if (isEdit) {
      setValuesToForm(backToFrontMapper(welcomeOffer), formValue)
    }

    const rules = {
      title: {
        required: true,
        max: apiDefaultValidators.maxTinyTextLength
      },
      bonus_type: {
        required: true
      },
      bonus_id: {
        required: true
      },
      min_deposit: {
        required: true,
        min_value: 0
      },
      min_wager_sum: {
        required: true,
        min_value: 0
      }
    }

    const handleSubmit = async ([, res]) => {
      if (res) {
        await emit('updateStatusCallback', true)
        await emit('submit', res)
      }
    }

    return {
      formValue,
      formResource,
      rules,
      bonusResource,
      bonusTypesOptions,
      handleSubmit
    }
  }
}
</script>
