<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :filters="filters"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @hide="(val) => closeModal(val)"
    >
      <welcome-offer-editor
        :welcome-offer="selected"
        :bonus-types="bonusTypes"
        :bonuses-options="bonusesOptions"
        @updateStatusCallback="updateStatusCallback"
        @submit="handleSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  VxTable,
  VxCellLink,
  VxCellButtons
} from '@/components/table'
import {
  passDataToResource,
  welcomeOffers,
  useResource,
  bonuses
} from '@/services/resources'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import WelcomeOfferEditor from '@/views/welcome-offers/WelcomeOfferEditor'
import { VxButton } from '@/components/buttons'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute } from '@/router'
import { ref, computed, onBeforeMount } from '@vue/composition-api'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { keyBy, mapValues } from 'lodash'
import { boolStatusesOptions } from '@/services/utils'
import { bonusUrls } from '@/views/bonuses/bonusesService'

export default {
  name: 'WelcomeOffers',
  components: {
    VxTable,
    VxPageTitleActions,
    WelcomeOfferEditor,
    VxButton
  },
  setup () {
    const tableRef = ref(null)
    const modalIsOpen = ref(false)
    const selected = ref(null)
    const bonusTypes = ref([])
    const bonusesRef = ref({})
    const tableToUpdate = ref(false)

    const bonusesOptions = computed({
      get: () => bonusesRef.value,
      set: (val) => {
        bonusesRef.value = val
        bonusTypes.value = Object.values(bonusesRef.value)
      }
    })

    const updateStatusCallback = (value) => {
      tableToUpdate.value = value
    }

    const resource = passDataToResource(welcomeOffers.getAll, {
      requestParams: {
        params: { include: 'bonus' }
      }
    })

    const getBonusesOptions = async () => {
      const { can, callRequest } = useResource(bonuses.money)

      if (can) {
        const [, res] = await callRequest({ params: { per_page: 100 } })
        if (!res) return
        bonusesOptions.value = mapValues(keyBy(res.data, 'id'), 'name')
      }
    }

    const isActiveButton = ({ id, is_enabled }) => {
      const payload = {
        requestParams: {
          urlParams: { id },
          data: { is_enabled: !is_enabled }
        }
      }
      const updatedStatusResource = passDataToResource(welcomeOffers.toggleStatus, payload)

      const turnOff = { ...buttons.turnOff, label: 'Enabled' }
      const turnOn = { ...buttons.turnOn, label: 'Disabled' }

      return [{
        ...is_enabled ? turnOff : turnOn,
        resource: updatedStatusResource
      }]
    }

    onBeforeMount(async () => {
      await getBonusesOptions()
    })

    const { can: canCreate } = useResource(welcomeOffers.create)
    const { can: canUpdate } = useResource(welcomeOffers.update)

    const modalTitle = computed(() => {
      return (selected.value ? 'Edit' : 'Create') + ' Welcome Offer'
    })

    const openModal = (bonus = null) => {
      selected.value = bonus
      modalIsOpen.value = true
    }

    const closeModal = () => {
      if (tableToUpdate.value) {
        tableRef.value.refresh()
      }

      modalIsOpen.value = false
      selected.value = null
      tableToUpdate.value = false
    }

    const handleSubmit = () => {
      closeModal()
    }

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'title'
      },
      {
        key: 'is_enabled',
        label: 'Status',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: isActiveButton(item)
        })
      },
      {
        key: 'bonus',
        label: 'Bonus Info',
        component: VxCellLink,
        tdAttr: (value, key, { bonus: { id, name, bonus_type } = {} }) => ({
          label: name,
          subLabel: `#${id}`,
          href: buildRoute(bonusUrls[bonus_type], { query: { id } })
        })
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: {
          buttons: [
            {
              ...buttons.edit,
              can: canUpdate,
              onClick: openModal
            }
          ]
        }
      }
    ]

    const filters = [
      {
        key: 'id',
        label: 'ID',
        type: filterTypes.number
      },
      {
        key: 'title',
        label: 'Title',
        type: filterTypes.text
      },
      {
        key: 'isenabled',
        label: 'Status',
        type: filterTypes.select,
        options: boolStatusesOptions,
        reduce: ({ value }) => value,
        optionsLabel: 'label'
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      filters,
      canCreate,

      selected,
      handleSubmit,
      bonusTypes,
      bonusesOptions,
      updateStatusCallback,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
